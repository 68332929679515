@tailwind base;
@tailwind components;
@tailwind utilities;
@supports (-webkit-touch-callout:none) {
  .min-h-screen {
    min-height: -webkit-fill-available;
  }
    .h-screen {
    height: -webkit-fill-available;
  }
}